html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

@import "./node_modules/bootstrap/scss/_functions.scss";
@import "./node_modules/bootstrap/scss/_variables.scss";

@import "_myvariables.scss";

@import "./node_modules/bootstrap/scss/_mixins.scss";
@import "./node_modules/bootstrap/scss/_reboot.scss";

@import "./node_modules/bootstrap/scss/mixins/_breakpoints.scss";
@import "./node_modules/bootstrap/scss/mixins/_grid-framework.scss";
@import "./node_modules/bootstrap/scss/mixins/_grid.scss";

@import "./node_modules/bootstrap/scss/_grid.scss";
@import "./node_modules/bootstrap/scss/utilities/_display.scss";
@import "./node_modules/bootstrap/scss/utilities/_flex.scss";
@import "./node_modules/bootstrap/scss/utilities/_spacing.scss";

@import "./node_modules/bootstrap/scss/_spinners.scss";
@import "./node_modules/bootstrap/scss/_modal.scss";
@import "./node_modules/bootstrap/scss/_alert.scss";


@import "_mymixins.scss";

//MY PATH VARIABLES
//$fontsPath: "/fonts";
$imagesPath: "../../../www-root/images";
$icomoon-font-path: "../../../www-root/fonts";