@import "_includes.scss";
@import "_lightGallery.scss";

//COLORS
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorBlue: #1084bf;
$colorRed: #ad131f;
$colorLightGrey: #f0f0f0;
$colorGrey: #c0c0c0;
$colorDarkGrey: #909090;

//ICOMOON icons
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-bike: "\e910";
$icon-calendar: "\e911";
$icon-castle: "\e912";
$icon-coffee: "\e913";
$icon-warning: "\e904";
$icon-chevron-down: "\e90c";
$icon-chevron-left: "\e90d";
$icon-chevron-right: "\e90e";
$icon-chevron-up: "\e90f";
$icon-check: "\e90a";
$icon-export: "\e905";
$icon-help-with-circle: "\e908";
$icon-info-with-circle: "\e909";
$icon-magnifying-glass: "\e903";
$icon-new-message: "\e906";
$icon-text-document: "\e90b";
$icon-users: "\e907";
$icon-facebook-with-circle: "\e900";
$icon-instagram-with-circle: "\e901";
$icon-youtube-with-circle: "\e902";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?htflha') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?htflha') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?htflha##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bike {
    &:before {
        content: $icon-bike;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-castle {
    &:before {
        content: $icon-castle;
    }
}
.icon-coffee {
    &:before {
        content: $icon-coffee;
    }
}
.icon-warning {
    &:before {
        content: $icon-warning;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-export {
    &:before {
        content: $icon-export;
    }
}
.icon-help-with-circle {
    &:before {
        content: $icon-help-with-circle;
    }
}
.icon-info-with-circle {
    &:before {
        content: $icon-info-with-circle;
    }
}
.icon-magnifying-glass {
    &:before {
        content: $icon-magnifying-glass;
    }
}
.icon-new-message {
    &:before {
        content: $icon-new-message;
    }
}
.icon-text-document {
    &:before {
        content: $icon-text-document;
    }
}
.icon-users {
    &:before {
        content: $icon-users;
    }
}
.icon-facebook-with-circle {
    &:before {
        content: $icon-facebook-with-circle;
    }
}
.icon-instagram-with-circle {
    &:before {
        content: $icon-instagram-with-circle;
    }
}
.icon-youtube-with-circle {
    &:before {
        content: $icon-youtube-with-circle;
    }
}
//ICOMOON icons END

//GLOBALS
*{
    margin: 0;
    padding: 0;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
    min-width: 320px;
}

body {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    height: 100%;
    padding: 0;
    margin: 0;
    color: $colorBlack;
    background-color: $colorWhite;
}

.clearfix {
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

a {
    color: $colorBlue;
    transition: all .2s ease-in-out 0s;
    text-decoration: underline;

    img {
        border: none !important;
        border: 0 !important;
        display: block;
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $colorBlue;
        text-decoration: underline;
    }

    &:hover {
        text-decoration: none;
        color: $colorBlue;

        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            color: $colorBlue;
            text-decoration: none;
        }
    }
}

.colored-frame {
    a {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
}

.button {
    display: inline-block;
    color: $colorWhite;
    background-color: $colorBlue;
    border: 1px solid $colorBlue;
    text-decoration: none;
    padding: 13px 25px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: all .2s ease-in-out 0s;

    &:hover:not(.active):not(.disabled) {
        text-decoration: none;
        color: $colorBlue;
        background-color: $colorWhite;

        &.no-left-border {
            border-left: none;
        }

        &.no-right-border {
            border-right: none;
        }
    }

    &.more-buttons-in-row {
        margin-left: 10px;
        margin-right: 10px;

        @include respond-below(md) {
            margin-left: 5px;
            margin-right: 5px;
        }

        &:first-child {
            margin-left: 0;

            @include respond-below(md) {
                margin-left: 0;
            }
        }

        &:last-child {
            margin-right: 0;

            @include respond-below(md) {
                margin-right: 0;
            }
        }
    }

    &.no-left-radius {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.no-right-radius {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.small {
        padding: 5px 10px;
    }

    &.smaller {
        padding: 4px 8px;
        font-size: 15px;
        line-height: 19px;
    }

    &.smallest {
        font-size: 14px;
        line-height: 20px;
        padding: 2px 6px;
    }

    &.active {
        .number {
            font-weight: 700;
        }

        text-decoration: none;
        color: $colorBlue;
        background-color: $colorWhite;

        &.no-left-border {
            border-left: none;
        }

        &.no-right-border {
            border-right: none;
        }
    }

    &.negative {
        color: $colorBlue;
        background-color: $colorWhite;
        border: 1px solid $colorBlue;

        &:hover:not(.active):not(.disabled) {
            color: $colorWhite;
            background-color: $colorBlue;

            &.no-left-border {
                border-left: none;
            }

            &.no-right-border {
                border-right: none;
            }
        }

        &.disabled {
            color: $colorGrey;
            background-color: $colorWhite;
            border-color: $colorGrey;
            cursor: default;

            &.no-left-border {
                border-left: none;
            }

            &.no-right-border {
                border-right: none;
            }
        }

        &.active {
            color: $colorWhite;
            background-color: $colorBlue;

            &.no-left-border {
                border-left: none;
            }

            &.no-right-border {
                border-right: none;
            }
        }
    }

    &.disabled {
        color: $colorWhite;
        background-color: $colorGrey;
        border-color: $colorGrey;
        cursor: default;

        &.no-left-border {
            border-left: none;
        }

        &.no-right-border {
            border-right: none;
        }
    }

    &.no-left-border {
        border-left: none;
    }

    &.no-right-border {
        border-right: none;
    }

    &.margin-3 {
        margin: 3px;
    }

    &.vertical-align-middle {
        display: inline-block;
        vertical-align: middle;
    }    

    .number {
        position: relative;
        display: block;
        height: 20px;
        width: 10px;
        font-size: 16px;
        line-height: 20px;
    }

    .icon {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;

        &:before {
            font-size: 24px;
            line-height: 24px;
            position: absolute;
            left: 50%;
            top: 50%;
            transition: color 0s ease-in-out 0s;
            transform: translate(-50%, -50%);
        }
    }

    .spinner-border, .spinner-grow {
        width: 20px;
        height: 20px;
        position: relative;
        top: -1px;
        margin-right: 10px;
    }
}
.padding-right-50 {
    padding-right: 50px;
}

.modal-header .close {
    font-size: 40px;
    cursor: pointer;
    color: $colorDarkGrey;

    &:hover {
        color: $colorBlack;
    }
}

.my-close {
    cursor: pointer;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    &:before, &:after {
        position: absolute;
        top: 0;
        left: 15px;
        content: '';
        height: 33px;
        width: 2px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}



.button.no-right-border + .button.negative {
    border-left-color: $colorBlue;
}

i, .italic {
    font-style: italic;
}

u, .underline {
    text-decoration: underline;
}

.uppercase {
    text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 700;
    margin: 20px 0 12px 0;
    padding: 0;
    color: $colorRed;

    &:first-child {
        margin-top: 0;
    }

    &.border {
        border-bottom: 7px solid $colorRed;
        padding-bottom: 6px;
    }

    &.hp-header {
        margin-top: 30px;
        margin-bottom: 50px;
        color: $colorWhite;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
        font-weight: 400;        
        text-align: center;

        b {
            font-weight: 600;
        }

        span {
            display: inline-block;
        }

        .big {
            font-size: 66px;
            line-height: 76px;
        }

        .small {
            margin-top: 20px;
            font-size: 44px;
            line-height: 52px;
        }

        @include respond-below(md) {
            margin-top: 20px;
            margin-bottom: 40px;
            text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);

            .big {
                font-size: 50px;
                line-height: 60px;
            }

            .small {
                margin-top: 15px;
                font-size: 33px;
                line-height: 42px;
            }
        }

        @include respond-below(sm) {
            display: none;

            margin-top: 10px;
            margin-bottom: 30px;
            text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);

            .big {
                font-size: 40px;
                line-height: 50px;
            }

            .small {
                margin-top: 10px;
                font-size: 22px;
                line-height: 30px;
            }
        }
    }
}

.horizontal-rule {
    display: block;
    width: 100%;
    height: 7px;
    background-color: $colorRed;
}

h1 {
    font-size: 32px;
    line-height: 40px;

    @include respond-below(md) {
        font-size: 28px;
        line-height: 36px;
    }
    @include respond-below(sm) {
        font-size: 24px;
        line-height: 30px;
    }
}

h2 {
    font-size: 28px;
    line-height: 36px;

    @include respond-below(md) {
        font-size: 24px;
        line-height: 30px;
    }
    @include respond-below(sm) {
        font-size: 22px;
        line-height: 28px;
    }
}

h3 {
    font-size: 24px;
    line-height: 30px;

    @include respond-below(md) {
        font-size: 22px;
        line-height: 28px;
    }

    @include respond-below(sm) {
        font-size: 21px;
        line-height: 27px;
    }
}

h4 {
    font-size: 22px;
    line-height: 28px;

    @include respond-below(md) {
        font-size: 21px;
        line-height: 27px;
    }

    @include respond-below(sm) {
        font-size: 19px;
        line-height: 26px;
    }
}

h5 {
    font-size: 21px;
    line-height: 27px;

    @include respond-below(sm) {
        font-size: 19px;
        line-height: 26px;
    }

    @include respond-below(sm) {
        font-size: 18px;
        line-height: 24px;
    }
}

h6 {
    font-size: 19px;
    line-height: 26px;

    @include respond-below(sm) {
        font-size: 18px;
        line-height: 24px;
    }

    @include respond-below(sm) {
        font-size: 17px;
        line-height: 23px;
    }
}

.h1 {
    @extend h1;
}

.h2 {
    @extend h2;
}

.h3 {
    @extend h3;
}

.h4 {
    @extend h4;
}

.h5 {
    @extend h5;
}

.h6 {
    @extend h6;
}

.fake-heading {
    display: block;
    width: 1px;
    height: 1px;
    position: absolute;
    left: -9999px;
    color: transparent;
    background-color: transparent;
}

p {
    margin-bottom: 12px;
}

ul, ol {
    margin-bottom: 12px;
    list-style: none;
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=); //Edge fix
    li {
        margin-left: 30px;
        &:before {
            color: $colorRed;
            font-weight: 700;
            display: inline-block;
            margin-left: -20px;
            width: 20px;
        }
    }
}
ol {
    counter-reset: li;
    li {
        counter-increment: li;
        position: relative;
        &:before {
            content: counter(li)".";
            position: absolute;
            text-align: right;
            right: calc(100% + 6px);
            top: 0;
            margin-left: -26px;
            width: 26px;
        }
        &.button-at-start {
            &:before {
                top: 6px;
            }
        }
    }
}
ul {
    li {
        &:before {
            content: "\2022";
        }
    }
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}
//
//table {
//    width: 100% !important;
//    text-align: left;
//    margin-bottom: 12px;
//    border: none !important;
//    word-break: break-word;
//
//    @include respond-below(sm) {
//        display: block;
//        width: 100%;
//
//        thead, tbody, tfoot {
//            display: block;
//            width: 100%;
//        }
//    }
//
//    tr {
//        @include respond-below(sm) {
//            display: block;
//            width: 100%;
//        }
//
//        th, td {
//            border: none;
//            padding: 6px 8px;
//            border-top: 1px solid #000000;
//            border-left: 1px solid #000000;
//
//            @include respond-below(sm) {
//                display: block;
//                width: 100%;
//                border-top: 1px solid #000000;
//                border-left: 2px solid #000000;
//                border-right: 2px solid #000000;
//            }
//
//            &:first-child {
//                border-left: 2px solid #000000;
//
//                @include respond-below(sm) {
//                    border-top: 2px solid #000000;
//                }
//            }
//            &:nth-last-child(2) {
//                word-break: normal;
//            }
//            &:last-child {
//                border-right: 2px solid #000000;
//
//                @include respond-below(sm) {
//
//                }
//            }
//        }
//        th {
//            font-weight: 700;
//        }
//        td {
//            font-weight: 400;
//        }
//
//        &:first-child {
//            th, td {
//                border-top: 2px solid #000000;
//
//                @include respond-below(sm) {
//                    border-top: 1px solid #000000;
//
//                    &:first-child {
//                        border-top: 2px solid #000000;
//                    }
//                }
//            }
//        }
//        &:last-child {
//            th, td {
//                border-bottom: 2px solid #000000;
//
//                @include respond-below(sm) {
//                    border-bottom: none;
//
//                    &:last-child {
//                        border-bottom: 2px solid #000000;
//                    }
//                }
//            }
//        }
//    }
//
//    &.table-auto {
//        width: auto !important;
//        //table-layout: auto;
//    }
//
//    &.table-layout-fixed {
//        table-layout: fixed;
//    }
//}

//.weight-300, .font-light, .font-book {
//    font-weight: 300;
//}

.weight-400, .font-regular {
    font-weight: 400;
}

.weight-600, .font-medium {
    font-weight: 600;
}

.weight-700, .font-bold, b, strong, .bold {
    font-weight: 700;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center-mobile {
    @include respond-below(sm) {
        text-align: center;
    }
}

.text-left-mobile {
    @include respond-below(sm) {
        text-align: left;
    }
}

.display-table {
    display: table;
}

.display-table-cell {
    display: table-cell;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

.display-none, .hidden {
    display: none !important;
}

.overflowhidden {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}
.overflowyhidden {
    overflow-x: visible;
    overflow-y: hidden;
}
.overflowxhidden {
    overflow-x: hidden;
    overflow-y: visible;
}
.overflowvisible {
    overflow-x: visible;
    overflow-y: visible;
}

.margin-bottom-0 {
    margin-bottom: 0;
}
.margin-bottom-5 {
    margin-bottom: 5px;
}
.margin-bottom-10 {
    margin-bottom: 10px;
}
.margin-bottom-15 {
    margin-bottom: 15px;
}
.margin-bottom-12 {
    margin-bottom: 12px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-top-0 {
    margin-top: 0;
}
.margin-top-5 {
    margin-top: 5px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-15 {
    margin-top: 15px;
}
.margin-top-12 {
    margin-top: 12px;
}
.margin-top-20 {
    margin-top: 20px;
}
.margin-bottom-20-t-10 {
    margin-bottom: 20px;

    @include respond-below(md) {
        margin-bottom: 10px;
    }
}
.margin-top-20-t-10 {
    margin-top: 20px;

    @include respond-below(md) {
        margin-top: 10px;
    }
}
.margin-minus-20 {
    margin: -20px;

    @include respond-below(md) {
        margin: -10px;
    }
}

.row-inner-nicely {
    margin-left: -($grid-gutter-width/2);
    margin-right: -($grid-gutter-width/2);

    @include respond-below(md) {
        margin-left: -($grid-gutter-width/4);
        margin-right: -($grid-gutter-width/4);
    }

    > .col {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;

        @include respond-below(md) {
            padding-left: $grid-gutter-width/4;
            padding-right: $grid-gutter-width/4;
        }
    }
    > [class*="col-"] {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;

        @include respond-below(md) {
            padding-left: $grid-gutter-width/4;
            padding-right: $grid-gutter-width/4;
        }
    }
    > [class*=" col-"] {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;

        @include respond-below(md) {
            padding-left: $grid-gutter-width/4;
            padding-right: $grid-gutter-width/4;
        }
    }
}
.color-white {color: $colorWhite;}
.color-black {color: $colorBlack;}
.color-blue {color: $colorBlue;}
.colo-red {color: $colorRed;}
.color-lightgrey {color: $colorLightGrey;}
.color-grey {color: $colorGrey;}

.colored-frame {
    padding: 20px;
    border: 7px solid $colorRed;
    border-radius: 3px;
}

.margin-top-on-mobile {
    @include respond-below(sm) {
        margin-top: 20px;
    }
}

//WRAPPER CONTENT vs FOOTER
$footerHeightDesktop: 470px;
$footerHeightTablet: 470px;
$footerHeightMobile: 580px;

//.wrapper {
//    min-height: 100%;
//    height: auto !important;
//    height: 100%;
//    margin: 0 auto (-$footerHeightDesktop);
//
//    @include respond-below(md) {
//        margin: 0 auto (-$footerHeightTablet);
//    }
//
//    @include respond-below(sm) {
//        margin: 0 auto (-$footerHeightMobile);
//    }
//}
//.push {
//    height: $footerHeightDesktop;
//
//    @include respond-below(md) {
//        height: $footerHeightTablet;
//    }
//
//    @include respond-below(sm) {
//        height: $footerHeightMobile;
//    }
//}
//WRAPPER CONTENT vs FOOTER END

body {
    .wrapper {
        position: relative;
        background-color: $colorLightGrey;

        .wrapper-bg {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: center center;
            background-size: cover;
            transition: opacity .5s ease-in-out 0s;
        }
    }

    header {
        position: relative;
        height: auto;
        background-color: rgba(255,255,255,0.7);
        border-bottom: 7px solid $colorRed;
        padding: 20px 0;

        .header-logo {
            float: left;
            display: block;

            img {
                width: auto;
                height: 60px;

                @media(max-width:359px) {
                    max-width: 212px;
                    height: auto;
                }
            }
        }

        .search {
            float: right;
            margin-top: 9px;
            margin-right: 20px;
            position: relative;

            @include respond-below(sm) {
                margin-right: 0;
                margin-top: 16px;
                width: 100%;
            }

            form {
                input {
                    display: inline-block;
                    height: 50px;
                    line-height: 50px;
                    width: 280px;
                    vertical-align: top;
                    padding: 0 15px;
                    border: none;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 0;
                    background-color: $colorWhite;

                    &::-webkit-input-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &:-moz-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &::-moz-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &:-ms-input-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &::placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }

                    @include respond-below(sm) {
                        width: calc(100% - 50px);
                    }
                }

                button {
                    display: inline-block;
                    vertical-align: top;
                    background-color: $colorWhite;
                    border: none;
                    height: 50px;
                    line-height: 50px;
                    width: 50px;
                    position: relative;
                    border-top-left-radius: 0;
                    border-top-right-radius: 3px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 3px;
                    transition: background-color .2s ease-in-out 0s;

                    .icon {
                        transition: color .2s ease-in-out 0s;
                        color: $colorBlue;
                        font-size: 40px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        background-color: $colorBlue;

                        .icon {
                            color: $colorWhite;
                        }
                    }
                }
            }

            .search-hint {
                display: none;
                width: 100%;
                position: absolute;
                top: calc(100% + 1px);
                left: 0;
                right: 0;
                z-index: 1;
                border-radius: 3px;
                background-color: rgba(255,255,255,0.9);
                box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
                padding: 10px 7px 7px 7px;

                &.active {
                    display: block;
                }

                .hint {
                    display: block;
                    width: 100%;
                    height: auto;
                    padding: 4px 8px;
                    background-color: $colorWhite;
                    border-radius: 3px;
                    margin-bottom: 1px;
                    box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);

                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .header {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 22px;
                    margin-top: 7px;
                }

                .item {
                    text-decoration: none;

                    .header-text {
                        text-decoration: underline;
                        display: block;
                        width: 100%;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 2px;
                    }

                    .small-text {
                        color: $colorBlack;
                        display: block;
                        width: 100%;
                        font-size: 10px;
                        line-height: 14px;
                    }

                    &:hover {
                        .header-text {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .language-wrapper {
            margin-top: 9px;
            float: right;
            font-size: 15px;
            position: relative;
            background-color: $colorWhite;
            color: $colorBlack;
            z-index: 2;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;

            @media(max-width:359px) {
                margin-top: 3px;
                margin-bottom: 6px;
            }

            .language-selected {
                height: 50px;
                line-height: 50px;
                width: 68px;
                display: block;
                padding: 0 15px;
                cursor: pointer;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                transition: color .2s ease-in-out 0s, background-color .2s ease-in-out 0s;

                .text {
                    margin-right: 6px;
                }

                .icon {
                    transition: color .2s ease-in-out 0s;
                    color: $colorBlue;
                }

                .icon-chevron-down {
                    display: inline;
                }

                .icon-chevron-up {
                    display: none;
                }

                &:hover {
                    background-color: $colorBlue;
                    color: $colorWhite;

                    .icon {
                        color: $colorWhite;
                    }
                }
            }

            .language-list {
                position: absolute;
                left: 0;
                top: 50px;
                display: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;

                .language {
                    display: block;
                    padding: 0 15px;
                    height: 50px;
                    line-height: 50px;
                    width: 68px;
                    float: left;
                    clear: left;
                    background-color: $colorWhite;
                    color: $colorBlack;
                    text-decoration: none;

                    .text {
                        text-decoration: none;
                    }

                    &:hover {
                        background-color: $colorBlue;
                        color: $colorWhite;
                        text-decoration: none;

                        .text {
                            text-decoration: none;
                        }
                    }

                    &:last-child {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                }

            }

            &:hover, &.active {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                .language-list {
                    display: block;
                }
            }
            &.active {
                .icon-chevron-down {
                    display: none;
                }

                .icon-chevron-up {
                    display: inline;
                }
            }
        }
    }

    footer {
        position: relative;
        font-size: 14px;
        line-height: 20px;
        color: $colorWhite;
        //height: $footerHeightDesktop;
        padding: 20px 0 10px 0;
        background-color: $colorRed;
        text-align: center;
        overflow: hidden;

        //@include respond-below(md) {
        //    height: $footerHeightTablet;
        //}
        //
        //@include respond-below(sm) {
        //    height: $footerHeightMobile;
        //}

        a {
            color: $colorWhite;

            &:hover {
                color: $colorWhite;
            }
        }

        .with-icon {
            position: relative;
            display: inline-block;
            padding-left: 25px;
            padding-bottom: 2px;
            margin-bottom: 2px;

            .icon {
                font-size: 20px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
                opacity: 1;
            }

            &:hover {
                .icon {
                    opacity: 0.9;
                }
            }
        }

        .slash-divide {
            display: inline-block;
            margin-right: 20px;
            position: relative;

            &:after {
                content: "";
                display: block;
                width: 1px;
                height: 20px;
                background-color: $colorWhite;
                position: absolute;
                left: calc(100% + 10px);
                top: 50%;
                transform: translate(0, -50%);
            }

            @include respond-below(sm) {
                margin-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .single-lane-on-mobile {
            display: inline-block;

            @include respond-below(sm) {
                display: block;
            }

            &:last-child {
                .slash-divide {
                    margin-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .footer-logo {
            display: inline-block;

            img {
                width: auto;
                height: 50px;
            }
        }

        .footer-logo-smaller {
            img {
                width: auto;
                height: 30px;
            }
        }

        .footer-logo-white-bg {
            padding: 8px;
            background-color: $colorWhite;
        }

        .social {
            display: inline-block;
            margin: 0 5px;
            text-decoration: none;
            opacity: 1;

            .icon {
                font-size: 30px;
            }

            &:hover {
                text-decoration: none;
                opacity: 0.9;

                .icon {
                    text-decoration: none;
                }
            }

            img {
                display: block;
                width: 36px;
                height: auto;
            }
        }
    }

    main {
        padding: 20px 0;

        @include respond-below(sm) {
            padding: 10px 0;
        }

        .content-wrapper {
            background-color: rgba(255, 255, 255, 0.9);
            padding: 20px;
            border-radius: 3px;

            @include respond-below(md) {
                padding: 10px;
            }
        }

        .content {
            background-color: $colorWhite;
            padding: 20px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;

            @include respond-below(md) {
                padding: 10px;
            }
        }

        .content-heading {
            margin-bottom: 20px;
        }

        .content-heading,
        .content-image {
            position: relative;
            max-height: 500px;
            overflow: hidden;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;

            @include respond-below(md) {
                max-height: none;
                margin-bottom: -2px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            a {
                display: block;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                margin-bottom: 0;

                @include respond-below(md) {
                    max-height: none;
                    margin-bottom: 0;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }

            .header-wrapper {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                padding: 20px;
                background-color: rgba(0, 0, 0, 0.5);

                * {
                    color: $colorWhite;
                    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);

                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .perex {
                    font-size: 18px;
                    line-height: 24px;
                }

                @include respond-below(md) {
                    position: static;
                    left: auto;
                    bottom: auto;
                    right: auto;
                    background-color: $colorWhite;
                    margin-top: 10px;
                    margin-bottom: 0;
                    padding: 20px 10px 14px 10px;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;

                    * {
                        color: $colorBlack;
                        text-shadow: none;
                    }

                    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                        color: $colorRed;
                        border-bottom: 7px solid $colorRed;
                        padding-bottom: 6px;
                    }

                    .perex {
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 600;
                    }
                }
            }
        }

        .content-heading + .content {
            @include respond-below(md) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .gallery-item-wrapper {
            margin-bottom: 20px;

            &:nth-last-child(-n+4) {
                margin-bottom: 0;
            }

            @include respond-below(sm) {
                width: 33.3333333333%;

                &:nth-last-child(-n+4) {
                    margin-bottom: 20px;
                }

                &:nth-last-child(-n+3) {
                    margin-bottom: 0;
                }
            }

            @media(max-width:539px) {
                width: 50%;

                &:nth-last-child(-n+3) {
                    margin-bottom: 20px;
                }

                &:nth-last-child(-n+2) {
                    margin-bottom: 0;
                }
            }

            @media(max-width:339px) {
                width: 100%;

                &:nth-last-child(-n+2) {
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include respond-below(md) {
                margin-bottom: 10px;

                &:nth-last-child(-n+4) {
                    margin-bottom: 0;
                }

                @include respond-below(sm) {
                    width: 33.3333333333%;

                    &:nth-last-child(-n+4) {
                        margin-bottom: 10px;
                    }

                    &:nth-last-child(-n+3) {
                        margin-bottom: 0;
                    }
                }

                @media(max-width:539px) {
                    width: 50%;

                    &:nth-last-child(-n+3) {
                        margin-bottom: 10px;
                    }

                    &:nth-last-child(-n+2) {
                        margin-bottom: 0;
                    }
                }

                @media(max-width:339px) {
                    width: 100%;

                    &:nth-last-child(-n+2) {
                        margin-bottom: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        a.gallery-item  {
            display: block;
            width: 100%;
            height: auto;
            padding: 0;
            background-color: $colorLightGrey;
            position: relative;
            border: 1px solid $colorLightGrey;
            border-radius: 3px;
            overflow: hidden;

            .image-frame {
                display: block;
                width: 100%;
                height: auto;
            }

            .video-image-wrapper,
            .image-wrapper {
                display: block;
                position: absolute;
                left: 0px;
                top: 0px;
                right: 0px;
                bottom: 0px;
                overflow: hidden;

                .image-to-show {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: none;
                    opacity: 0;
                    transition: opacity .2s ease-in-out 0s;

                    &.full-height {
                        height: 100%;
                        width: auto;
                    }
                    &.full-width {
                        width: 100%;
                        height: auto;
                    }
                    &.active {
                        display: block;
                    }
                    &.visible {
                        opacity: 1;
                    }
                }
            }

            .icon-wrapper {
                position: absolute;
                right: 0;
                bottom: 0;
                display: block;
                width: 42px;
                height: 42px;
                font-size: 25px;
                line-height: 42px;
                text-align: center;
                color: transparent;
                background-color: transparent;
                transition: all .2s ease-in-out 0s;
            }

            .video-image-wrapper {
                height: 82%;
            }

            &:hover {
                .icon-wrapper {
                    color: #ffffff;
                    text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
                }
            }
        }

        .map-wrapper {

            .map {
                display: block;
                width: 100%;
                height: 450px;
                overflow: hidden;
                border-radius: 3px;

                .card {
                    font-family: "Open Sans", sans-serif;
                    border-radius: 3px;
                    padding: 0;
                    width: 220px;

                    .close::after {
                        color: $colorWhite;
                        text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
                    }

                    .card-header {

                        img {
                            height: 100px;
                            width: auto;
                        }

                        .img-cover {
                            width: 100%;
                            height: 100px;
                            position: relative;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: cover;
                        }
                    }

                    .card-body {
                        padding: 10px;
                        background: $colorLightGrey;

                        h3 {
                            color: $colorRed;
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 600;
                            margin-top: 0;
                            margin-bottom: 10px;
                        }

                        p {
                            color: $colorBlack;
                            margin-bottom: 0;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }

                    .button {
                        padding-top: 1px;
                        padding-bottom: 3px;
                    }
                }
            }
        }

        .elipsis-mobile {
            &:last-child {
                margin-bottom: 0;
            }

            @include respond-below(sm) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .months {
            position: relative;
            padding: 0 50px;
            text-align: center;

            .button {                
                font-size: 16px;
                line-height: 34px;
                margin: 2px;
                height: 34px;
                width: calc(16.6666666666% - 4px);
                padding: 0;
                transition: none;
                transition: color .2s ease-in-out 0s, background-color .2s ease-in-out 0s;

                @include respond-below(md) {
                    font-size: 12px;
                }

                @include respond-below(sm) {
                    font-size: 16px;
                    width: calc(100% - 4px);
                }

                &:first-child, &:last-child {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    height: 34px;
                    width: 48px;
                    margin: 0;

                    @include respond-below(sm) {
                        height: calc(100% - 4px);
                    }
                }

                &:first-child {
                    left: 0;
                }

                &:last-child {
                    right: 0;
                }

                .icon {
                    font-size: 16px;
                    line-height: 34px;
                    width: auto;
                    height: 34px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .box {
            display: block;
            width: 100%;
            height: 100%;
            background-color: $colorWhite;
            padding: 20px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;

            @include respond-below(md) {
                padding: 10px;
            }

            &.opacity-less {
                opacity: 0.5;
            }

            &.highlighted {
                border: 1px solid $colorBlue;
            }

            .image-cover {
                border-radius: 3px;
                overflow: hidden;
                display: block;
                width: 100%;
                height: 174px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

            }

            .box-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-top: 20px;
                height: calc(100% - 174px);

                @include respond-below(sm) {
                    padding-top: 10px;
                }

                &>* {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .box-text-wrap {
                    display: block;

                    .header {
                        display: inline-block;
                    }

                    .perex {
                        display: block;
                    }
                }

                .categories {
                    display: block;
                }
            }
        }

        .form {

            .form-row {
                text-align: left;
                margin-bottom: 12px;

                &.text-center {
                    text-align: center;
                }

                .red {
                    color: $colorRed;
                }

                label {
                    display: block;
                    width: 100%;
                    margin-bottom: 4px;
                }

                .icon {
                    font-size: 20px;
                    position: relative;
                    top: 2px;
                }

                ul {
                    list-style: none;

                    li{
                        margin-left: 0;
                        position: relative;
                        padding-left: 30px;

                        &:before {
                            display: none;
                        }

                        .icon {
                            position: absolute;
                            left: 0;
                            top: 2px;
                        }
                    }
                }

                input, textarea, select {
                    font-size: 16px;
                    display: block;
                    width: 100%;
                    border: 1px solid #929292;
                    color: $colorBlack;
                    padding: 0 20px;
                    margin: 0;
                    text-align: left;
                    border-radius: 3px;
                    background-color: $colorWhite;

                    &::-webkit-input-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &:-moz-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &::-moz-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &:-ms-input-placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }
                    &::placeholder {
                        color: $colorBlack;
                        opacity: 1;
                    }

                    &:hover {
                        border: 1px solid rgba(16, 132, 191, 0.8);
                    }

                    &:focus {
                        border: 1px solid $colorBlue;
                    }

                    &.border-placeholder-blue {
                        border: 1px solid $colorBlue;
                        cursor: pointer;

                        &::-webkit-input-placeholder {
                            color: $colorBlue;
                            font-weight: 700;
                            opacity: 1;
                        }
                        &:-moz-placeholder {
                            color: $colorBlue;
                            font-weight: 700;
                            opacity: 1;
                        }
                        &::-moz-placeholder {
                            color: $colorBlue;
                            font-weight: 700;
                            opacity: 1;
                        }
                        &:-ms-input-placeholder {
                            color: $colorBlue;
                            font-weight: 700;
                            opacity: 1;
                        }
                        &::placeholder {
                            color: $colorBlue;
                            font-weight: 700;
                            opacity: 1;
                        }
                    }

                    &.text-center {
                        text-align: center;
                    }
                }

                input, select {
                    height: 50px;
                    line-height: 50px;
                }

                textarea {
                    padding-top: 9px;
                    min-height: 200px;
                    min-width: 100%;
                    max-width: 100%;

                    &.min-height-100 {
                        min-height: 100px;
                    }
                }

                button, .button {
                    //margin: 20px 0 0 0;
                    margin-top: 20px;
                    line-height: 20px;
                    vertical-align: top;
                }

                ul {
                    list-style: none;
                    display: block;
                    width: 100%;
                    text-align: left;
                    margin-top: 4px;
                    margin-bottom: 0;

                    li {
                        margin-left: 0;

                        &.red {
                            display: none;
                            color: $colorRed;
                        }

                        &.confirmation {
                            color: $colorRed;
                            font-weight: bold;
                        }
                    }

                }

                &.error {
                    input, textarea, select {
                        border: 1px solid $colorRed;
                    }

                    ul {
                        li {
                            &.red {
                                display: list-item;
                            }
                        }
                    }
                }

                &.form-row-checkbox {
                    label {
                        display: block;
                        padding-top: 0;
                        visibility: visible;
                        background-image: url('#{$imagesPath}/layout/form_checkbox.png');
                        background-size: 20px 2560px;
                        background-repeat: no-repeat;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                    }
                    input[type=checkbox]	{
                        position: absolute;
                        z-index: -1000;
                        left: -10000px;
                        top: 0px;
                        overflow: hidden;
                        height: 1px;
                        width: 1px;
                        margin: -1px;
                        padding: 0;
                        border: 0;
                        visibility: hidden;
                        opacity: 0;
                    }
                    input[type=checkbox] + label	{
                        padding-left: 30px;
                        display: inline-block;
                        //overflow: hidden;
                        line-height: 22px;
                        background-repeat: no-repeat;
                        background-position: 0 1px;
                        vertical-align: middle;
                        cursor: pointer;
                        color: $colorBlack;
                        width: 100%;
                    }
                    input[type=checkbox] + label:hover	{
                        background-position: 0 -1899px;
                    }
                    input[type=checkbox]:checked + label {
                        background-position: 0 -1259px;
                        color: $colorBlack;
                    }
                    input[type=checkbox]:checked + label:hover {
                        background-position: 0 -2539px;
                    }
                }

                &.form-row-radio {
                    label {
                        display: block;
                        padding-top: 0;
                        visibility: visible;
                        background-image: url('#{$imagesPath}/layout/form_radio.png');
                        background-size: 20px 2560px;
                        background-repeat: no-repeat;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                    }
                    input[type=radio]	{
                        position: absolute;
                        z-index: -1000;
                        left: -10000px;
                        top: 0px;
                        overflow: hidden;
                        height: 1px;
                        width: 1px;
                        margin: -1px;
                        padding: 0;
                        border: 0;
                        visibility: hidden;
                        opacity: 0;
                    }
                    input[type=radio] + label	{
                        padding-left: 30px;
                        display: inline-block;
                        //overflow: hidden;
                        max-height: 166px;
                        line-height: 22px;
                        background-repeat: no-repeat;
                        background-position: 0 1px;
                        vertical-align: middle;
                        cursor: pointer;
                        color: $colorBlack;
                        width: 100%;
                    }
                    input[type=radio] + label:hover	{
                        background-position: 0 -1899px;
                    }
                    input[type=radio]:checked + label {
                        background-position: 0 -1259px;
                        color: $colorBlack;
                    }
                    input[type=radio]:checked + label:hover {
                        background-position: 0 -2539px;
                    }
                }

                &.form-row-checkbox, &.form-row-radio {
                    &.error {
                        ul {
                            margin-top: -1px;
                            margin-bottom: 5px;
                        }
                    }

                    &>.hover-underline {
                        label {
                            &:hover {
                                text-decoration: underline;
                                text-decoration-color: $colorBlue;

                                .count {
                                    text-decoration: underline;
                                    text-decoration-color: $colorBlue;
                                }
                            }
                        }
                    }

                    &>.label-with-count {
                        label {
                            padding-right: 30px;
                            position: relative;

                            .count {
                                display: inline-block;
                                position: absolute;
                                top: 0;
                                right: 0;
                            }
                        }
                    }
                }

                &.margin-bottom-0 {
                    margin-bottom: 0;
                }
            }
        }

        .tips-header {
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            border: 1px solid $colorBlue;
            background-color: $colorWhite;
            color: $colorBlue;
            text-decoration: none;
            border-radius: 3px;
            padding: 10px 0;

            h1, h2, h3, h4, h5, h6 {
                text-decoration: none;
                color: $colorBlue;
                line-height: 30px;
                margin-top: 0;
                margin-bottom: 0;
                transition: all .2s ease-in-out 0s;
                display: inline-block;                
                position: relative;
                padding: 0 4px 4px 4px;                

                @include respond-below(md) {
                    padding: 0 3px 3px 3px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    display: block;
                    width: 0;
                    height: 3px;
                    background-color: $colorBlue;
                    transition: all .2s ease-in-out 0s;
                    transform: translate(-50%, -50%);
                    border-radius: 2px;

                    @include respond-below(md) {
                        height: 2px;
                        border-radius: 1px;
                    }
                }
            }

            &:hover {
                border: 1px solid $colorBlue;
                background-color: $colorBlue;
                color: $colorWhite;

                h1, h2, h3, h4, h5, h6 {
                    color: $colorWhite;

                    &:before {
                        background-color: $colorWhite;
                    }
                }
            }

            &.active {
                border: 1px solid $colorRed;
                background-color: $colorRed;
                color: $colorWhite;

                h1, h2, h3, h4, h5, h6 {
                    color: $colorWhite;

                    &:before {
                        width: 100%;
                        background-color: $colorWhite;
                    }
                }
            }
        }

        .list-tips {
            display: none;
            opacity: 0;
            transition: opacity .2s ease-in-out 0s;

            &.active {
                display: block;
            }

            &.visible {
                opacity: 1;
            }

            //cervene odkazy na hp tips - nepouziva se
            //.box {
            //    .header {
            //        color: $colorRed;
            //        transition: all .2s ease-in-out 0s;
            //        text-decoration: underline;
            //
            //        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            //            color: $colorRed;
            //            text-decoration: underline;
            //        }
            //
            //        &:hover {
            //            text-decoration: none;
            //            color: $colorRed;
            //
            //           h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            //                color: $colorRed;
            //                text-decoration: none;
            //            }
            //        }
            //    }
            //
            //    .button {
            //        color: $colorRed;
            //        background-color: $colorWhite;
            //        border: 1px solid $colorRed;
            //
            //       &:hover:not(.active):not(.disabled) {
            //            color: $colorWhite;
            //            background-color: $colorRed;
            //        }
            //    }
            //}
        }

        .circle-wrapper {
            text-align: center;
            display: block;
            width: 100%;
            height: auto;
            padding-top: 100%;
            position: relative;
            margin-bottom: 30px;

            @include respond-below(sm) {
                margin-bottom: 14vw;
            }

            .circle {
                display: block;
                position: absolute;
                left: 14px;
                top: 14px;
                bottom: 14px;
                right: 14px;
                text-decoration: none;
                border-radius: 100%;
                border: 3px solid $colorWhite;
                background-color: rgba(0, 0, 0, 0.7);
                transition: background .2s ease-in-out 0s, border .2s ease-in-out 0s;

                @include respond-below(md) {
                    border: 2px solid $colorWhite;
                    left: 7px;
                    top: 7px;
                    bottom: 7px;
                    right: 7px;
                }
                @include respond-below(sm) {                    
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: -14px;
                    top: -14px;
                    bottom: -14px;
                    right: -14px;
                    border-radius: 100%;                    
                    border: 7px solid rgba(16, 132, 191, 0.8);
                    opacity: 1;
                    transition: border .2s ease-in-out 0s;

                    @include respond-below(md) {
                        left: -7px;
                        top: -7px;
                        bottom: -7px;
                        right: -7px;
                        border: 3px solid rgba(16, 132, 191, 0.9);
                    }

                    @include respond-below(sm) {
                        left: -4px;
                        top: -4px;
                        bottom: -4px;
                        right: -4px;
                        border: 2px solid rgba(16, 132, 191, 1);
                    }
                }

                .item-wrap {
                    display: inline-block;
                    position: absolute;
                    width: 80%;
                    height: auto;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    padding-bottom: 5%;

                    @include respond-below(sm) {
                        position: relative;
                        left: auto;
                        top: auto;
                        transform: translate(0, 0);
                        padding-bottom: 0;
                        height: 100%;
                        width: 100%;
                    }
                }

                span {
                    display: inline-block;
                    color: $colorWhite;
                    text-decoration: none;
                    transition: color .2s ease-in-out 0s;
                }

                .icon {
                    font-size: 120px;
                    transition: all .2s ease-in-out 0s;

                    @include respond-below(md) {
                        font-size: 70px;
                    }
                    @include respond-below(sm) {
                        font-size: 10vw;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .icon-calendar {
                    padding-right: 5%;
                }

                .icon-coffee {
                    padding-left: 5%;
                }

                .text {
                    margin-top: 10px;
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 400;

                    @include respond-below(md) {
                        margin-top: 5px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                    @include respond-below(sm) {
                        font-size: 2.7vw;
                        line-height: 3.6vw;
                        margin-top: 2vw;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%, 0);
                        text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
                        font-weight: 600;
                    }
                }

                &:hover, &.active {
                    background-color: rgba(255, 255, 255, 0.9);
                    border: 3px solid $colorRed;

                    @include respond-below(md) {
                        border: 2px solid $colorRed;
                    }

                    &:before {
                        border: 7px solid rgba(255, 255, 255, 0.5);
                        opacity: 1;

                        @include respond-below(md) {
                            border: 3px solid rgba(255, 255, 255, 0.5);
                        }
                        @include respond-below(sm) {
                            border: 2px solid rgba(255, 255, 255, 0.5);
                        }
                    }

                    span {
                        color: $colorRed;
                        text-decoration: none;
                    }

                    .text {
                        @include respond-below(sm) {
                            color: $colorWhite;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .list-selection {
            display: none;
            opacity: 0;
            transition: opacity .2s ease-in-out 0s;

            &.active {
                display: block;
            }

            &.visible {
                opacity: 1;
            }

            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                margin-bottom: 30px;

                @include respond-below(md) {
                    margin-bottom: 20px;
                }

                &.margin-bottom-0 {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.row-column {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    column-count: 3;
    width: 100%;

    @include respond-below(md) {
        column-count: 2;
        padding-left: 5px;
        padding-right: 5px;
    }
    @include respond-below(sm) {
        column-count: 1;
    }
}

.padding-fix-tablet {
    @include respond-below(md) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.fade-on-load {
    opacity: 0;
    transition: opacity .2s ease-in-out 0s;
}

html.window-on-load .fade-on-load {
    opacity: 1;
}

html.window-on-load body .wrapper .wrapper-bg {
    opacity: 1;
}

//DATERANGEPICKER custom styles
.daterangepicker {
    font-family: "Open Sans", sans-serif;

    td.active {
        font-weight: 600;
        background-color: $colorBlue;
    }

    td.start-date {
        border-top-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 0;
    }

    td.end-date {
        border-top-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 3px;
    }

    .drp-buttons {
        .btn {
            font-weight: 600;
            display: inline-block;
            color: $colorWhite;
            background-color: $colorBlue;
            border: 1px solid $colorBlue;
            text-decoration: none;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            transition: all .2s ease-in-out 0s;

            &:hover {
                color: $colorBlue;
                background-color: $colorWhite;
            }
        }
    }
}

//LIGHTGALLERY custom styles
#lightGallery-action a#lightGallery-prev:before, #lightGallery-action a#lightGallery-next:after, #lightGallery-action a.cLthumb:after, #lightGallery-Gallery .thumb_cont .thumb_info .close i:after, #lightGallery-close:after {
    position: absolute;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
}

.customHtml {
    font-family: "Open Sans", sans-serif;

    h1, h2, h3, h4, h5, h6 {
        color: $colorWhite;

        &:first-child {
            margin-top: 20px;
        }
    }
}

// outline reset - chrome
form *:focus, input:focus, select:focus  {
    outline: none;
}

//INDICATORS
#tablet-indicator, #mobile-indicator {
    position: absolute;
    left: -10000px;
    top: -10000px;
    width: 1px;
    height: 1px;
    display: none;
}
#tablet-indicator {
    display: none;

    @include respond-below(md) {
        display: block;
    }
    @include respond-below(sm) {
        display: none;
    }
}
#mobile-indicator {
    display: none;

    @include respond-below(sm) {
        display: block;
    }
}
//INDICATORS END

//DESKTOP FIRST
//desktop (min-width: 1200px)
@include respond-above(md) {

}
//tablet (max-width: 1199px)
@include respond-below(md) {

}
@include respond-below(sm) {

}
//mobil (max-width: 767px)




.tableWrap{
    min-width:100%;
    overflow: auto;
    table{
        min-width: 300px;
        margin-bottom: 12px;
    }
    table, tr, th, td{
        border: 1px solid black;
        border-collapse: collapse;
    }
    td, th{
        padding: 3px 5px;
    }
}

.tac{
    text-align: center;
}

